<template>
  <div v-if="preview">
    {{ previewTitle }}
  </div>
  <PreviewEditablePeople
    v-else
    v-model:open="open"
    v-bind="$props"
    @change="(...args: [string, string?]) => $emit('change', ...args)"
    @update-open="$emit('update:open', $event)"
  />
</template>

<script lang="ts" setup>
import type { FieldCallback } from '#field/types'

const props = defineProps<{
  value: string | undefined
  description?: string
  label?: string
  options: string | null | undefined
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  cellClass?: string
}>()

const { boardData } = useBoardSharedState()

const selectedValues = ref<string[]>(
  safeParseStringToJSON(props.value ?? '', [])
)

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'update:open', value: boolean): void
}>()

const open = ref(false)

const previewTitle = computed(() => {
  if (!selectedValues.value.length) {
    return '-'
  }

  if (selectedValues.value.length === 1) {
    return boardData.value?.users.find((user) => user.id === selectedValues.value[0])?.fullName
  }

  return `${selectedValues.value.length} people`
})

defineExpose({
  focus: () => {
    open.value = true
  },
})

onMounted(() => {
  if (!props.preview) {
    emit('set-callback', {
      open: () => {
        open.value = true
      },
    })
  }
})

watch(
  () => props.value,
  (val) => {
    selectedValues.value = safeParseStringToJSON(val ?? '', [])
  }
)
</script>
