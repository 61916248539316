<template>
  <div class="flex items-center justify-between pt-3 pb-2 px-4">
    <p class="font-semibold text-sm text-gray-900">{{ label }}</p>
    <UButton
      size="xs"
      icon="i-heroicons-x-mark"
      color="gray"
      variant="ghost"
      @click.prevent.stop="$emit('close')"
    />
  </div>
  <div class="px-4">
    <UInput
      v-model="search"
      icon="i-heroicons-magnifying-glass"
      size="sm"
      autofocus
      placeholder="Search by name or email"
      :ui="{
        base: '!rounded-md',
      }"
    />
  </div>
  <div
    class="max-h-[21.375rem] overflow-y-auto scroll-stable minimal-scrollbar mt-2 pl-2 pr-0.5 pb-2 space-y-0.5"
  >
    <div
      v-for="(option, index) in boardMembers"
      ref="optionRefs"
      :key="option.id"
      :class="[
        'flex items-center text-gray-900 justify-between px-2 py-1.5 cursor-pointer rounded-md gap-1.5 hover:bg-gray-100',
        {
          'bg-gray-100': currentFocusOption === index,
        },
      ]"
      @mouseenter="currentFocusOption = index"
      @mouseleave="currentFocusOption = -1"
      @click="onSelect(option.id)"
    >
      <div class="flex items-center gap-2">
        <Avatar
          :id="option.id"
          :src="option.photo"
          :name="option.fullName"
          size="2xs"
        />
        <Tooltip :text="option.fullName" arrow-class="!top-[unset]">
          <template #default="{ getTextRef }">
            <span
              :ref="getTextRef"
              class="text-sm line-clamp-1 break-all font-medium"
            >
              {{ option.fullName }}
            </span>
          </template>
        </Tooltip>
      </div>

      <Icon
        v-if="selectedValues.includes(option.id)"
        name="heroicons:check"
        :size="20"
        class="min-w-5"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { cloneDeep } from 'lodash-es'
import type { FieldCallback } from '#field/types'
import type { BaseUser } from '#auth/types'

const props = defineProps<{
  popoverRef: { ref: () => { open: boolean } }
  value: string | undefined
  description?: string
  label?: string
  options: string | null | undefined
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  cellClass?: string,
  selectedValues: string[]
}>()

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'update:open', value: boolean): void
  (e: 'close'): void
}>()

const { auth } = storeToRefs(useAuthStore())
const { boardData } = useBoardSharedState()

const currentFocusOption = ref(-1)
const search = ref('')
const optionRefs = ref<HTMLElement[]>([])
const open = ref(props.popoverRef.ref().open)

const boardMembers = computed(() => {
  const users = cloneDeep(boardData.value?.users || [])
  const selectedUsers = users.filter((user) => props.selectedValues.includes(user.id))
  const otherUsers = users.filter((user) => !props.selectedValues.includes(user.id))
  selectedUsers.sort((a, b) => props.selectedValues.indexOf(a.id) - props.selectedValues.indexOf(b.id))
  if (props.selectedValues.includes(auth.value?.id)) {
    bringMemberToTop(selectedUsers, auth.value?.id)
  }

  const sortedUsers = [...selectedUsers, ...otherUsers]

  return sortedUsers.filter((user) => {
    const fullName = user.fullName.toLowerCase()
    const email = user.email.toLowerCase()
    const searchValue = search.value.toLowerCase()
    return fullName.includes(searchValue) || email.includes(searchValue)
  })
})

const bringMemberToTop = (users: BaseUser[], id: string) => {
  const userIndex = users.findIndex((user) => user?.id === id)
  if (userIndex !== -1) {
    const cloneMember = users[userIndex]
    users.splice(userIndex, 1)
    users.unshift(cloneMember)
  }
}

const onChange = (value: string[]) => {
  emit('change', JSON.stringify(value), props.fieldId)
}

const onSelect = (value: string) => {
  let tempSelectedValues = cloneDeep(props.selectedValues)
  if (tempSelectedValues?.includes(value)) {
    tempSelectedValues = tempSelectedValues.filter((v) => v !== value)
  } else {
    tempSelectedValues.push(value)
  }

  onChange(tempSelectedValues)
}

const scrollToView = (index: number) => {
  if (optionRefs.value[index]) {
    optionRefs.value[index].scrollIntoView({
      block: 'nearest',
      inline: 'nearest',
    })
  }
}

defineShortcuts({
  arrowup: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value > 0) {
        currentFocusOption.value--
      } else {
        currentFocusOption.value = boardMembers.value.length - 1
      }
    },
  },
  arrowdown: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (currentFocusOption.value < boardMembers.value.length - 1) {
        currentFocusOption.value++
      } else {
        currentFocusOption.value = 0
      }

      scrollToView(currentFocusOption.value)
    },
  },
  enter: {
    whenever: [open],
    usingInput: true,
    handler: () => {
      if (
        currentFocusOption.value >= 0 &&
        boardMembers.value[currentFocusOption.value]
      ) {
        onSelect(boardMembers.value[currentFocusOption.value].id)
      }
    },
  },
})
</script>
