<template>
  <div
    :class="['h-full w-full group/cell', { '!cursor-not-allowed': disabled }]"
  >
    <DeferredPopover
      ref="popoverRef"
      :ui="{
        width: 'min-w-52 w-full max-w-[280px]',
        wrapper: 'h-full',
        trigger: `h-full flex items-center ${
          disabled ? '!cursor-not-allowed' : ''
        }`,
      }"
      :disabled="disabled"
      v-bind="$attrs"
      @update:open="$emit('update:open', $event)"
    >
      <template #content>
        <PreviewPopoverContentPeople
          :popover-ref="popoverRef"
          v-bind="$props"
          :selected-values="selectedValues"
          @change="$emit('change', $event, fieldId)"
          @close="popoverRef.close()"
        >
        </PreviewPopoverContentPeople>
      </template>
      <div
        :class="[
          'w-full h-full flex items-center',
          { active: open },
          cellClass,
        ]"
      >
        <div :class="['pl-2 pr-8 flex items-center z-10']">
          <div
            v-if="selectedValues?.length"
            class="flex items-center"
          >
            <UTooltip
              v-for="(people, index) in selectedPeople.slice(0, 4)"
              :key="people.id"
              :class="[{ '-ml-1': index !==0 }, `z-${(5-index)*10}`]"
              :text="people.fullName"
              :popper="{ placement: 'top' }"
            >
              <Avatar
                :id="people.id"
                :src="people.photo"
                :name="people.fullName"
                size="xs"
                rounded="ring-1 ring-white rounded-full"
              />
            </UTooltip>
            <div v-if="selectedPeople.length > 4" class="ml-0.5 text-2xs">
              {{ `+${selectedPeople.length - 4}` }}
            </div>
          </div>
          <div
            v-else
            class="leading-4 text-xs group-hover/cell:visible invisible"
          >
            -
          </div>
          <div
            class="absolute right-1 top-1/2 -translate-y-1/2 flex items-center"
          >
            <UButton
              v-if="selectedValues.length && !disabled"
              color="gray"
              variant="ghost"
              size="2xs"
              icon="i-heroicons-x-mark"
              class="group-hover/cell:visible invisible"
              @click.prevent.stop="onClear"
            />
            <Icon
              v-else
              name="heroicons:chevron-down"
              class="text-gray-500 group-hover/cell:visible invisible mr-1"
              size="2xs"
            />
          </div>
        </div>
      </div>
    </DeferredPopover>
  </div>
</template>

<script lang="ts" setup>
import type { FieldCallback } from '#field/types'

const open = defineModel('open', {
  type: Boolean,
  default: false,
})

const props = defineProps<{
  value: string | undefined
  description?: string
  label?: string
  options: string | null | undefined
  preview?: boolean
  fieldId?: string
  disabled?: boolean
  cellClass?: string
}>()

const emit = defineEmits<{
  (e: 'change', value: string, id?: string): void
  (e: 'set-callback', callback: Partial<FieldCallback>): void
  (e: 'update:open', value: boolean): void
}>()

const popoverRef = ref()
const { boardData } = useBoardSharedState()

const selectedValues = ref<string[]>(
  safeParseStringToJSON(props.value ?? '', [])
)

const selectedPeople = computed(() => {
  return boardData.value?.users
    .filter((user) => selectedValues.value.includes(user.id))
    .sort((a, b) => selectedValues.value.indexOf(a.id) - selectedValues.value.indexOf(b.id))
})

const onClear = () => {
  emit('change', '', props.fieldId)
  popoverRef.value.close()
}

watch(
  () => props.value,
  (val) => {
    selectedValues.value = safeParseStringToJSON(val ?? '', [])
  }
)
</script>
